<template>
        <div class="slideshow">
            <transition-group name="fade" tag="div" class="slides">
                <div v-for="item in filteredSlides" :key="item.src" class="slide">
                    <img :src="item.src" v-if="item.type == 'img'" loading="lazy" alt="background image">
                    <video :src="item.src" v-else-if="item.type == 'video'" autoplay loop muted></video>
                    <!-- <iframe :src="item.src" v-if="item.type == 'web'" frameborder="0"></iframe>
                    <div class="slideInfo" v-if="'text' in item">
                        <div class="slideHeading">{{item.text}}</div>
                        <div v-if="'url' in item"><button @click="openLink(item.url)">More Info</button></div>
                    </div> -->
                    <iframe width="100%" height="100%" v-if="item.type == 'youtube'" :src="item.src+'?playlist=DcCmg45jfKk&loop=1&autoplay=1&controls=0&mute=1&modestbranding=1&autohide=1&showinfo=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </transition-group>
            <!-- <div el="btn" @click="next">Next</div>
            <div el="btn" @click="prev">Prev</div> -->
        </div>
</template>

<script>

export default {
  name: 'SlideshowAdv',
  //props: ['data'],
  data() {
    return {
        speed: 2.5, //in seconds
        autoplay: true,
        action: null,
        currentSlide: 0,
        slides: [
            {
                src: require("@/assets/img/club_house.webp"),
                type: 'img',
                // speed: 5,
            },
            // {
            //     src: 'https://www.youtube.com/embed/DcCmg45jfKk',
            //     type: 'youtube',
            //     speed: 600,
            // },
            {
                src: require("@/assets/video/rbshoa_720.mp4"),
                type: 'video',
                speed: 600,
            },
            
            
        ],
    }
  },
  computed:{
      filteredSlides(){
          var newArr = [];
          this.slides.forEach((item, index)=>{
              if(index <= this.currentSlide && index >= (this.currentSlide - 1)){
                  newArr.push(item);
              }
          })
          return newArr;
      }
  },
  methods: {
    next() {
        var x = this, speed = x.speed*1000;
        x.currentSlide = (x.currentSlide+1) % x.slides.length;
        if ('speed' in this.slides[x.currentSlide]) {
            speed = this.slides[x.currentSlide].speed * 1000;
        }
        this.clearAction();
        this.action = setTimeout(function() {
            x.next();
        }, speed);

    },
    prev() {
        var x = this, speed = x.speed*1000;
        x.currentSlide = x.currentSlide-1 < 0? x.slides.length - 1 : (x.currentSlide-1) % x.slides.length;
        if ('speed' in this.slides[x.currentSlide]) {
            speed = this.slides[x.currentSlide].speed * 1000;
        }
        this.clearAction();
        this.action = setTimeout(function() {
            x.prev();
        }, speed);
    },
    clearAction() {
        if (this.action != null) {
            clearTimeout(this.action);
        }
    }
  },
    mounted() {
        var x = this;
        if (x.autoplay == true && x.slides.length > 1) {
            setTimeout(function() {
                x.next();
            }, x.speed * 1000);
        }
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.slideshow {
            background-color: #000;
            overflow: hidden;
            border-radius: 8px;
        }

        .slideshow .slides {
            position: relative;
            overflow: hidden;
            /* height: 500px; */
            width: 100%;
            opacity: 0;
            animation: fade-in forwards 2s;
        }

        @keyframes fade-in {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .slideshow .slide,
        .slideshow .slide>* {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
        }
        .slideshow .slide>iframe{
            height:100vmax;
            width:100vmax;
            top:50%;
            transform:translateY(-50%);
        }
        @media(max-width:800px){
            .slideshow .slide>iframe {
                height: 500px;
                width: 150vmin;
                left: 50%;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }

        /*
        .slide>* {
            animation: fade-in forwards 1s;
        }
        */

        .slideshow .slideInfo {
            background-color: hsla(0, 0%, 0%, 0.3);
            padding: 40px;
            color: #fff;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-shadow: 0 3px 5px rgba(0, 0, 0, 1);
        }

        /*
        .slideshow .slideInfo>* {
            max-width: 900px;
            width: 100%;
        }
        */

        .slideshow .slideHeading {
            font-size: 4vmax;
            line-height: 1.3em;
        }

</style>
