<template>
        <footer>
            <ul>
                <li>
                    <h4>Helpful Links</h4>
                    <div>
                        <!-- <a href="/amenities/pool">Pool Schedule</a><br> -->
                        <!-- <a href="/amenities/reservations">Clubhouse Reservations</a><br> -->
                        <a href="/amenities">Amenities</a>
                    </div>
                </li>
                <!-- <li>
                    <h4>Community</h4>
                    <div>
                        <a href="/community/news">News</a><br>
                        <a href="/community/events">Events</a><br>
                        <a href="/community/requests">Submit a request</a>
                    </div>
                </li> -->
                <li>
                    <h4>Contact Us</h4>
                    <div>
                        <a href="/contact">HOA Mgmt Company</a><br>
                        <a href="/contact">Area Contacts</a>
                    </div>

                </li>
            </ul>
            <div class="author" @click="openLink('https://burkedesigns.biz')">
                <svg class="footerlogo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 852">
                    <title>logo</title>
                    <path d="M346.73,326.27c22.89,8.56,44.33,19,64,32.76,49.31,34.4,84.22,79.23,96.67,138.88,13.65,65.37-2.49,124.17-44.69,175.36-43.42,52.66-100.65,81.62-167.17,93.45-19.89,3.54-40,4.43-60.13,4.17-3.83,0-8.27.5-9.07-4.84-.91-6.11,4.09-6.43,8-7.12,69-12.13,109.33-73.54,114.61-128.81,4.08-42.61-2.61-82.52-29-117.32-24.17-31.9-56.65-49.47-96.49-54.27-33.28-4-66.63-2.79-100-3-26.4-.19-43.74-16.79-43.79-43.19q-.24-139.45,0-278.91c0-25.06,13.13-41.64,37.75-47.23,69.68-15.82,135.79-7.71,196.37,31.94,29.35,19.21,51.71,44.93,62.57,78.76,14,43.63,5.2,83.64-22.25,119.89C352,319.8,349.53,322.74,346.73,326.27Z"></path>
                    <path d="M1142.61,296.13c-.57,21.24.94,46.63-.81,72a132.09,132.09,0,0,1-8.87,40.12c-10.77,26.9-30.37,44.15-58.21,51.68a152.61,152.61,0,0,1-72.89,1.7c-38.77-8.4-60.54-33.69-68.53-71.71a176.68,176.68,0,0,1-3.51-37c.15-45.52.06-91,.07-136.55,0-12.41,1.06-13.43,13.82-13.41q15.32,0,30.62,0c12.83,0,13.76.93,13.77,13.46,0,45.52-.07,91,0,136.55,0,11.6,1.3,23.07,5.71,34,7.73,19.1,22.69,28.15,45.28,27.33,21.27-.78,35.28-11,41.34-30.47a89.2,89.2,0,0,0,3.69-26.91q-.14-69.93-.05-139.85c0-12.69,1.51-14.14,14.34-14.11,11.31,0,22.62.13,33.93-.06,7.32-.12,10.39,3.07,10.33,10.46C1142.49,239.6,1142.61,265.81,1142.61,296.13Z"></path>
                    <path d="M1496,337.11c0,36.46,0,72.74,0,109,0,13.58-.73,14.3-14.66,14.3-11,0-22.07-.13-33.1.05-7.22.12-10.51-2.85-10.5-10.33q.15-118.34,0-236.66c0-8,3.54-10.74,11.08-10.57,11.85.26,23.72,0,35.58.08,9.89,0,11.61,1.74,11.62,11.51q0,49.65,0,99.3v9.63c18.71-31.52,39.07-60.06,58.12-89.45,5.54-8.56,11.33-17,16.79-25.56,2.48-3.9,5.65-5.54,10.26-5.5,15.17.16,30.34.11,45.51.05,3.76,0,7.18.35,9.14,4.16,2.14,4.15.15,7.29-2.24,10.55-24.1,32.88-47.95,66-72.36,98.61-5.26,7-5.08,11.9,0,18.86,26.49,35.92,52.5,72.18,78.62,108.38,2.2,3,5.53,5.91,4.53,10.2-1.06,4.57-4.3,6.73-9.11,6.72-17.1-.07-34.2-.1-51.3,0-4.42,0-7.17-2.08-9.45-5.49q-38.28-57.3-76.62-114.56C1497.31,339.45,1498.58,337.62,1496,337.11Z"></path>
                    <path d="M1676.21,331q0-58.32,0-116.64c0-10.13,1.29-11.38,11.53-11.38q62.45,0,124.9,0c10.33,0,11.91,1.65,12,12.07q.09,11.58,0,23.16c-.1,10.64-1.74,12.2-12.7,12.21q-32.67,0-65.34,0c-10.59,0-12.14,1.52-12.16,11.77q0,16.95,0,33.91c0,9.22,1.87,11.12,11.11,11.16,20.68.08,41.36.3,62,0,8-.13,11.06,3.37,11,10.79,0,8.55-.07,17.1,0,25.65.05,7.36-2.82,11-10.86,10.89-20.68-.34-41.36-.15-62-.09-9.31,0-11.22,1.95-11.25,11,0,11.58,0,23.16,0,34.74,0,11.8.66,12.42,12.88,12.43q35.14,0,70.31,0c10.89,0,12.81,2,12.83,13.05,0,8.27-.15,16.54,0,24.81.17,7.07-3.19,9.9-10,9.89q-67-.12-134,0c-8.06,0-10.37-3.67-10.32-11.21C1676.33,409.88,1676.22,370.45,1676.21,331Z"></path>
                    <path d="M151.76,610.33c0-22.59,0-45.18,0-67.77,0-19.24,10.73-29.65,29.75-29.15,52.57,1.4,84.77,31.6,89.83,84.2,2.39,24.75-1.67,48.29-14,70C244.62,690,225,702.27,199.57,705.34A166.92,166.92,0,0,1,179,706.76c-15.91-.06-27-11.09-27.17-27C151.61,656.62,151.76,633.47,151.76,610.33Z"></path>
                    <path d="M1564.16,647.63c-11.9,4.67-14.88,2.8-14.87-9.3,0-35-.08-70,.11-105,0-3.91-2.56-10.47,3.76-10.9s14-3.89,19.26,4.48c20.74,33.08,41.87,65.92,62.85,98.84,4.61-1.86,1.67-5.37,1.79-8.21,0-29,.11-57.89-.12-86.84,0-6,.84-9.06,8-9,7.61.07,7.6,4.32,7.59,9.65,0,35.83.06,71.67-.09,107.51,0,3.69,2,9.74-3.78,10.13-6.21.41-13.41,3.44-18.45-4.59-16.25-25.86-33-51.38-49.63-77l-13.44-20.79c-4.58,1.16-2.28,4.87-2.29,7.26-.19,28.27-.1,56.53-.15,84.79C1564.68,641.65,1565.22,644.7,1564.16,647.63Z"></path>
                    <path d="M1447.75,613.34c0,5.24-.75,10.61.16,15.69,1.88,10.52-3.53,14.81-12.49,17.48-27.3,8.12-54,9.7-75-13.24-20.35-22.21-21.44-49.06-10.28-75.66,10.46-24.93,31-36.87,58-37.59,10.83-.29,21.45.7,31.67,4.48,4.47,1.65,7.44,10.85,4.56,14.41-2.47,3.06-5.4,1.24-8.25.15-11.2-4.28-22.71-6.66-34.75-4.76-22.59,3.56-38.51,20.35-41,43.24a76.14,76.14,0,0,0,.31,20.62c5.31,32,36.08,48.78,66.25,36.28,3.49-1.44,5.41-3.23,5.33-7.2-.16-8-.18-16,0-24,.11-5-2-7-6.93-6.82-4.4.19-8.83-.17-13.22.09-4.92.29-7.72-.73-7.63-6.66.09-5.47,2-7.2,7.34-7,9.64.34,19.31.36,28.94,0,5.65-.21,7.17,2.21,7,7.35C1447.56,597.9,1447.76,605.63,1447.75,613.34Z"></path>
                    <path d="M898.13,585.79c0-18.74.16-37.48-.11-56.21-.08-5.58,1.4-7.84,7.4-7.72,16.8.34,33.61.29,50.41,0,5.66-.09,7,2.31,7,7.45s-2.24,6.58-7,6.5c-11.57-.2-23.14.15-34.71-.13-6-.14-8.47,1.84-8.18,8.1.41,9.08.23,18.18.06,27.27-.1,5,1.92,6.82,6.89,6.71,10.74-.25,21.5.13,32.23-.16,5.77-.15,6.72,2.62,6.83,7.61.12,5.5-2.43,6.43-7.06,6.34-10.47-.2-20.94.12-31.41-.14-5.13-.12-7.65,1.37-7.5,7,.27,10.18.19,20.38,0,30.57-.08,5,1.92,6.84,6.89,6.76,12.94-.22,25.9.07,38.84-.16,5-.09,6.86,1.73,6.84,6.7s-1.43,7.41-7.1,7.31c-17.9-.32-35.82-.25-53.72-.05-5,.05-6.75-1.63-6.69-6.71C898.29,623.81,898.15,604.8,898.13,585.79Z"></path>
                    <path d="M1799.79,520.05c5.44-.52,11.69.5,17.87,1.93,3.25.74,6,1.93,6.52,6.24,1.06,9.53-1,11.42-10.18,8.49-9.87-3.17-20-4.3-30.08-1.58-7.48,2-13.71,5.83-15.38,14.31-1.65,8.32,1.81,14.58,8.41,19.1,4.74,3.24,10,5.81,15,8.62,6.47,3.62,13.24,6.8,19.42,10.86,15.07,9.9,20.83,22.4,17.15,37.82s-15.62,21.93-30,24.59a80.13,80.13,0,0,1-39.92-3,10.71,10.71,0,0,1-6.19-15.12c2.13-3.9,5-2.29,7.74-1a59.32,59.32,0,0,0,31.12,6.24c12.1-.89,19-5.54,21.4-14.61,2.33-8.89-1.31-16.8-11.74-23.06s-21.35-11.36-31.68-17.62c-9.25-5.59-16.23-13.25-16.87-24.89-1.17-21.31,13.82-36,38.16-37.36C1793.31,519.91,1796.07,520.05,1799.79,520.05Z"></path>
                    <path d="M1089.45,651.77c-10.26-.53-19.55-1.57-28.36-5-4.32-1.7-6.61-12.15-3.52-15.53,2.63-2.88,5.09-.55,7.43.53a61.91,61.91,0,0,0,32.07,5.81c10.51-1,17.68-5.83,19.83-13.45,2.6-9.22-.23-16.91-10.06-23-10.7-6.69-22.2-12.08-33-18.62-12.89-7.8-19.66-19-16.69-34.63,2.92-15.38,14.06-22.33,27.8-25.95,11.9-3.13,24-2.57,36-.08,6.85,1.41,10.21,8.26,7.15,14.3-1.92,3.79-5,2.4-7.86,1.38-10-3.59-20.24-5.07-30.79-2.57-8.31,2-15.13,6-16.76,15-1.54,8.46,2.43,15.17,9.64,19.41,10.18,6,20.85,11.14,31,17.21s18.64,13.94,20.12,26.65c2.3,19.81-9.26,33.78-30.9,37.42C1098,651.32,1093.23,651.43,1089.45,651.77Z"></path>
                    <path d="M1231.15,586.27c0-18.76.17-37.52-.1-56.27-.08-6.1,2-8.15,8.16-8.22s7.23,2.6,7.21,8q-.26,55.86,0,111.72c0,6-1.58,8.19-7.87,8.19-6.8,0-7.56-3.3-7.5-8.78C1231.28,622.68,1231.15,604.47,1231.15,586.27Z"></path>
                    <path d="M1564.16,647.63V542.91a3.92,3.92,0,0,1,3,3.7c-2.57,2.19-1.29,5.14-1.3,7.7-.11,28.28,0,56.56-.15,84.83C1565.67,642,1567,645.23,1564.16,647.63Z"></path>
                    <path d="M1637.06,617.57c1.9,2.87,1.64,6.56,1,9.16-.88,3.4-2.17.67-2.77-1C1638,623.5,1635.57,620.11,1637.06,617.57Z"></path>
                    <path d="M890.11,373.26c-3.19-25.27-25.82-45.92-55.84-51.64,11.71-5.08,22.33-11.17,31-20.3,24.9-26.12,19.36-66.86-11.69-85-13.62-8-28.58-11.49-44.09-12.41-33.07-2-66.18-.49-99.27-.85-11.24-.13-13,1.52-13,12.85q-.1,116.26,0,232.52c0,10,2.17,12,12.22,12,29.52,0,59,.08,88.55,0a128.9,128.9,0,0,0,41.43-6.47C875.82,441.56,894.9,411.25,890.11,373.26ZM766.19,246c10.71.23,21.46-.45,31.94,2.48,12.75,3.57,19.58,10.67,20.85,21.55,1.6,13.75-3.92,25.17-15.17,31.33-9,4.95-18.85,5.54-27.15,5.09-20.92,0-20.91,0-20.92-19.59,0-10.18.08-20.36-.06-30.54C755.59,249.09,758.8,245.87,766.19,246Zm38.39,168.8c-13.77,4.2-27.91,2.41-41.9,2.3-4.81,0-7-3.8-6.93-8.64.07-8.28,0-16.56,0-24.83,0-8,.1-16,0-24-.12-6.55,3-9.88,9.43-9.81,11.83.12,23.7-.77,35.49,1.31,16.94,3,27.48,13.64,28.6,29.32C830.44,397.29,821.58,409.64,804.58,414.83Z"></path>
                    <path d="M1404.66,445c-13.6-21.37-27.08-42.82-41-64-9.44-14.3-19.7-28-35.88-35.62,1.71-2.64,4.78-2.61,7.24-3.57a84.08,84.08,0,0,0,32.36-22.17c24.89-27.45,26.93-93-27.58-109.84-9.77-3-19.68-5.35-29.87-5.92-33.9-1.89-67.83-.4-101.74-.9-6.86-.1-10.14,3-10.14,9.95q.06,118.75,0,237.49c0,6.86,3.18,10.13,10.09,10.06,11.31-.11,22.62,0,33.93,0,13.61,0,14.21-.57,14.21-13.81,0-24.55.07-49.1-.06-73.65,0-7.55,3-10.92,10.84-11.13,9.76-.26,17.68,2.67,24.31,9.91a124.6,124.6,0,0,1,14.45,20.08c12.42,20.48,24.94,40.89,37.12,61.51,3,5.09,6.59,7.36,12.61,7.21,13.51-.36,27-.11,40.54-.1,4.14,0,8.33.37,10.87-4.11S1406.83,448.37,1404.66,445Zm-97.38-134.78c-9.65,7.14-20.77,7.81-32.21,7.68-18.81-.22-18.81-.08-18.81-18.54V260.5c0-12.35,1.27-13.61,13.67-13.62h9a63.44,63.44,0,0,1,21.37,2c11.84,3.4,19.65,11.08,21.61,23.44C1324.32,287.58,1320.16,300.64,1307.28,310.17Z"></path>
                    <path d="M799.41,569.87c-3.7-25.1-16.85-39.14-41.51-45.2-18.21-4.47-36.73-2.14-55.13-2.64-5.35-.15-4.95,4-4.95,7.63q0,27.69,0,55.38c0,19,.1,38-.07,57,0,5.11,1.42,7.62,7,7.45,11.84-.36,23.7.07,35.53-.45a62.16,62.16,0,0,0,33.27-10.87C797.85,621.4,803.46,597.34,799.41,569.87Zm-17.48,33.6c-5.81,19.74-23.39,31.62-46.92,32.19-22,.55-22,.55-22-21.37v-29c0-13.79.24-27.58-.1-41.36-.15-6.14,2.08-8.42,8.15-8.26,10.46.28,21-.15,31.22,2.43,16.13,4.05,26.58,13.8,30.44,30.2A67.72,67.72,0,0,1,781.93,603.47Z"></path>
                </svg>

            </div>
        </footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    openLink(url) {
        window.location.href = url;
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
footer {
            color: #fff;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        footer ul {
            width: 100%;
            max-width: 1200px;
            padding: 1.25rem;
            max-width: 1200px;
            list-style-type: none;
            margin: 0;
            display: flex;
            color: #fff;
        }

        footer li {
            margin: 1.25rem;
        }

        footer h4 {
            margin: 0;
        }

        footer ul a {
            text-decoration: none;
            color: hsla(0, 0%, 100%, 0.6);
        }

        .author {
            width: 100%;
            padding: 1.25rem;
            background-color: var(--secondary-color);
            display: flex;
            align-items: center;
        }

        .footerlogo {
            fill: rgba(0, 0, 0, 0.6);
            height: 40px;
        }
</style>
