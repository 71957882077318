import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/standard.scss'
// import {auth} from './firebase'
// Added
// import { auth } from './firebase'

createApp(App).use(store).use(router).mount('#app')
