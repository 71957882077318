import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// Added
// import { auth } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/amenities',
    name: 'Amenities',
    component: () => import('../views/Amenities.vue')
  },
  {
    path: '/posts/:id',
    name: 'Articles',
    component: () => import('../views/Article.vue')
  },
  {
      path: '/:pathMatch(.*)*',
      name: 'PageNotFound',
      component: () => import('../views/Home.vue')
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// Check if route requires Authentication
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

//   if (requiresAuth && auth.currentUser) {
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router
